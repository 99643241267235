<template>
  <div class="alert-table">
    <ag-grid-vue class="ag-theme-quartz-dark custom-grid" :rowData="getRowData(dataframe)" :columnDefs="columnDefs"
      :gridOptions="gridOptions" @rowClicked="onRowClicked"
      :tooltipShowDelay="0">
    </ag-grid-vue>
  </div>
</template>

<script>
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Or the theme you're using
import { AgGridVue } from 'ag-grid-vue3';

export default {
  name: 'AlertTable',
  components: {
    AgGridVue,

  },
  props: {
    alerts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      columnDefs: null,
      rowData: null,
      frameworkComponents: null,
      gridOptions: {
        defaultColDef: {
          sortable: true,
          filter: true,
          resizable: true,
          wrapText: true, // Wrap Text
          autoHeight: true, // Adjust Cell Height to Fit Wrapped Text
        },
        enableCellTextSelection: true,
        animateRows: true,
      },
    };
  },
  mounted() {
    this.columnDefs = [
      {
        field: 'date_happened',
        headerName: 'Date',
        sortable: true,
        filter: true,
        valueFormatter: (params) => {
          const date = new Date(params.value * 1000);
          const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          const timeZoneAbbr = timeZone.includes('America/New_York') ? 'EST' :
                          timeZone.includes('America/Chicago') ? 'CST' :
                          timeZone.includes('America/Denver') ? 'MST' :
                          timeZone.includes('America/Los_Angeles') ? 'PST' :
                          timeZone.includes('America/Phoenix') ? 'MST' :
                          timeZone.includes('America/Anchorage') ? 'AKST' :
                          timeZone.includes('America/Adak') ? 'HST' :
                          timeZone.split('/').pop();
          return `${date.toLocaleString(undefined, {
            timeZone: timeZone
          })} ${timeZoneAbbr}`;
        },
      },
      {
        field: 'title',
        headerName: 'Title',
        sortable: true,
        filter: true,
      },
      {
        field: 'resource',
        headerName: 'Resource',
        sortable: true,
        filter: true,
      },
      {
        field: 'services_affected',
        headerName: 'Services Affected',
        sortable: true,
        filter: true,
      },
      {
        field: 'regions_affected',
        headerName: 'Regions Affected',
        sortable: true,
        filter: true,
      },
      {
        field: 'severity',
        headerName: 'Severity',
        sortable: true,
        filter: true,
      },
      {
        field: 'potential_causes',
        headerName: 'Potential Causes',
        sortable: true,
        filter: true,
        tooltipField: 'potential_causes',
      },
    ];
  },
  methods: {
    onRowClicked(event) {
      let rowData = event.data;
      console.log('Row clicked: ', rowData);
      this.$emit('alert-row-clicked', rowData);
    },
    dateFormatter(params) {
      const date = new Date(params.value * 1000); // Assuming date_happened is a Unix timestamp in seconds
      return date.toLocaleString();
    },
    onGridReady(params) {
      // Get the grid API
      this.gridApi = params.api;
      this.gridApi.autoSizeAllColumns();
      this.gridApi.sizeColumnsToFit();
    },
    getRowData() {
      const rows = []
      for (const alert of this.alerts) {
        const row = {
          date_happened: alert.date_happened,
          title: alert.title,
          resource: alert.resource,
          services_affected: alert.services_affected,
          regions_affected: alert.regions_affected,
          severity: alert.severity,
          potential_causes: alert.potential_causes,
        };
        rows.push(row);
      }
      return rows;
    }

  },
};
</script>

<style scoped>
.alert-table {
  width: 800px;
  margin-top: 60px;
}

.ag-theme-quartz-dark.custom-grid {
  max-height: 300px;
  height: 250px;
  /* Necessary for the grid to render properly */
  overflow-y: auto;
  --ag-background-color: var(--colors-neutral-gray-800, #0C160B);
  --ag-foreground-color: var(--colors-white, #FFF);
  --ag-header-background-color: var(--colors-neutral-gray-700, #2A2F28);

}

/* Adjust AG Grid fonts and colors to match your theme */
.ag-theme-quartz-dark .ag-header-cell-label .ag-header-cell-text {
  color: var(--colors-white, #FFF);
  font-family: 'Roboto Mono', monospace;
}

.ag-theme-quartz-dark .ag-cell {
  color: var(--colors-white, #FFF);
  font-family: 'Roboto Mono', monospace;
}

/* Severity chip styles */
.severity-chip {
  display: inline-block;
  padding: 2px 5px;
  border-radius: 4px;
  color: white;
  font-weight: bold;
}

.severity-low {
  background-color: green;
}

.severity-medium {
  background-color: orange;
}

.severity-high {
  background-color: red;
}

/* For text wrapping in cells */
:deep(.ag-cell) {
  white-space: normal !important;
  line-height: 1.2 !important;
  padding: 16px;
}

/* Optional: Limit the maximum width of cells to handle long text */
.ag-cell {
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
