<!-- ChatMessage.vue -->
<template>
  <div :class="['chat-message', messageTypeClass]">
    <ProfileAvatar class="avatar" :imageSrc="avatarSrc" />
    <div class="message-content">
      <div class="message-row">

        <!-- Text Box -->
        <div class="text-box">
          <!-- Fixed Header: Key Findings -->
          <div v-if="parsedMessage" class="header-section">
            <h2 class="fixed-header">
              <font-awesome-icon icon="lightbulb" class="icon" />
              Key Findings:
            </h2>
            <div class="markdown-content" v-html="formattedAnswer"></div>
          </div>
          <!-- Fallback to original message -->
          <div v-else class="markdown-content" v-html="formattedMessage"></div>

          <!-- Replace existing evidence rendering logic in ChatMessage.vue -->

          <div v-if="parsedMessage && parsedMessage.evidence && parsedMessage.evidence.length > 0">
            <h2 class="fixed-header">
              <font-awesome-icon icon="search" class="icon" />
              Evidence:
            </h2>

            <!-- <div v-for="(evidenceItem, index) in parsedMessage.evidence" :key="'evidence-' + index">
              <EvidenceCollapsible
                :evidenceSummary="evidenceItem.summary"
                :evidenceDetails="evidenceItem.details"
                :artifact="artifactForToolId(evidenceItem.tool_id)"
                :tool_id="evidenceItem.tool_id"
                :count="getCountForEvidenceWithToolId(index)"
                @open-preview-modal="openPreviewModal"
                @highlight-preview-card="highlightCard"
                @remove-highlight-preview-card="removeHighlightCard"
                @open-preview-card="openPreviewModalForToolId"
              />
            </div> -->

            <!-- Vuetify v-timeline Component -->
            <v-timeline class="evidence-timeline" side="end" truncate-line="both" lineThickness="1" lineColor="#353E34"
              dotColor="#669D65">

              <v-timeline-item v-for="(evidenceItem, index) in parsedMessage.evidence" :key="'evidence-' + index"
                class="timeline-item" density="compact" size="8">
                <!-- Custom Dot -->
                <template #dot>
                  <div class="custom-dot"></div>
                </template>
                <!-- Content -->
                <EvidenceCollapsible :evidenceSummary="evidenceItem.summary" :evidenceDetails="evidenceItem.details"
                  :artifact="artifactForToolId(evidenceItem.tool_id)" :tool_id="evidenceItem.tool_id"
                  :count="getCountForEvidenceWithToolId(index)" @open-preview-modal="openPreviewModal"
                  @highlight-preview-card="highlightCard" @remove-highlight-preview-card="removeHighlightCard"
                  @open-preview-card="openPreviewModalForToolId" />
              </v-timeline-item>
            </v-timeline>
          </div>



          <!-- Display parsedMessage.next_steps which is a list of strings -->
          <div v-if="parsedMessage && parsedMessage.next_steps && parsedMessage.next_steps.length > 0">
            <h2 class="fixed-header">
              <font-awesome-icon icon="forward" class="icon" />
              Next Steps:
            </h2>
            <!-- make list fa icon denoted-->
            <div v-for="(nextStepObj, index) in parsedMessage.next_steps" :key="'nextStep-' + index">
              <font-awesome-icon icon="caret-right" class="icon" />
              <span>{{ " " + nextStepObj.text }}</span>
            </div>
          </div>

          <!-- Loading Animation -->
          <pre v-if="isStreaming" class="agent-logs-text"
            :class="{ faded: isStreaming }">{{ "Raw Agentic Output:" }}</pre>
          <div v-if="isStreaming" ref="agentLogsContainer" class="streaming-agent-logs">
            <div class="inner-streaming-agent-logs">
              <pre class="agent-logs-text" :class="{ faded: isStreaming }">{{ agentLogsWithAnimatedEllipsis }}</pre>
            </div>
          </div>

          <!-- <div v-if="isStreaming" class="loading-spinner-container">
            <LoadingSpinner :isLoading="isStreaming" />
          </div> -->
        </div>

        <!-- Collapsible Component -->
        <CollapsibleComponent ref="collapsible" v-if="type === 'system'">
          <template v-slot:header>
            <span>All Evidence</span>
          </template>

          <div class="preview-cards-container" :class="{ faded: isStreaming }">
            <PreviewCard v-for="(artifact, index) in sortedArtifacts" :key="'artifact-' + index" :artifact="artifact"
              :count="getCountForToolId(artifact.tool_call_id)"
              :isHighlighted="highlightedCard === artifact.tool_call_id" @open-preview-modal="openPreviewModal"
              showToolInfo="true" />
          </div>
        </CollapsibleComponent>

      </div>

      <div v-if="type === 'system' && !isStreaming" class="system-actions">
        <div class="buttons-group">
          <MiniButton type="ghost" icon="thumbs-up" @click="submitFeedback('thumbs_up')" />
          <MiniButton type="ghost" icon="thumbs-down" @click="submitFeedback('thumbs_down')" />
          <MiniButton type="ghost" icon="comment" @click="openFeedbackPopup" />
          <MiniButton type="secondary" icon="bug" @click="openAgentLogsModal">View Full Output</MiniButton>
        </div>
      </div>
    </div>

    <!-- Preview Modal -->
    <div v-if="showPreviewModal" class="modal-overlay" @click.self="closePreviewModal">
      <div class="modal-content">
        <!-- Modal Header -->
        <h3>{{ previewModalToolName }}</h3>
        <!-- Modal Body -->
        <div class="modal-body">
          <div class="modal-header" v-if="previewModalToolArgs">
            <pre>{{ formattedPreviewToolArgs }}</pre>
          </div>

          <!-- Display Error if present in Artifact -->
          <div v-if="previewModalArtifact.error" class="modal-error">
            <h3>Error</h3>
            <p>{{ previewModalArtifact.error }}</p>
          </div>

          <!-- Display Logs Dashboard if present in Artifact -->
          <div v-if="previewModalArtifact.artifact_display && previewModalArtifact.artifact_display.length === 1">
            <div v-if="previewModalArtifact.artifact_display[0].artifact_type === 'LOGS_DASHBOARD'">
              <LogsDashboardArtifact :logsDashboardArtifact="previewModalArtifact.artifact_display[0]" />
            </div>
            <div v-if="previewModalArtifact.artifact_display[0].artifact_type === 'MULTI_METRICS_DASHBOARD'">
              <MultiMetricsDashboardArtifact
                :multiMetricsDashboardArtifact="previewModalArtifact.artifact_display[0]" />
            </div>
          </div>


          <!-- Display Metrics -->
          <div v-if="previewModalArtifact.metrics.length">
            <div class="modal-metrics-content" v-for="(metric, index) in currMetrics" :key="'metric-' + index">
              <highcharts-chart :options="metric" :highcharts="Highcharts" />
            </div>
          </div>

          <!-- Display Images -->
          <!-- <div v-if="previewModalArtifact.images.length">
            <div class="modal-images">
              <img
                v-for="(image, index) in previewModalArtifact.images"
                :key="'image-' + index"
                :src="`data:image/png;base64,${image.image_data}`"
                class="modal-image"
              />
            </div>
          </div> -->

          <!-- Display Dataframes -->
          <div v-if="previewModalArtifact.dataframes.length">
            <div class="modal-dataframe-content" v-for="(dataframe, index) in currDataframes"
              :key="'dataframe-' + index">

              <div class="search-bar">
                <input type="text" v-model="quickFilterText" placeholder="Search..." class="quick-filter-input" />
              </div>

              <ag-grid-vue class="ag-theme-quartz-dark" style="width: 100%; height: 400px;"
                :columnDefs="dataframe.currColumnDefs" :rowData="dataframe.currRowData" :gridOptions="gridOptions"
                :quickFilterText="quickFilterText" @grid-ready="onGridReady"></ag-grid-vue>
            </div>
          </div>

          <!-- Display Code -->
          <div v-if="previewModalArtifact.code && previewModalArtifact.code.length">
            <div class="modal-code-content" v-for="(codeItem, index) in previewModalArtifact.code"
              :key="'code-' + index">
              <CodeBlock :code="codeItem.code" :language="codeItem.language" theme="everforest-dark" />
            </div>
          </div>

          <!-- Display Terminal Output -->
          <div v-if="previewModalArtifact.terminal_output && previewModalArtifact.terminal_output.length">
            <CodeBlock :code="previewModalArtifact.terminal_output" language="shellsession" theme="everforest-dark"
              class="terminal-output" />
          </div>


          <!-- Display Texts -->
          <div v-if="previewModalArtifact.texts.length">
            <div class="modal-text-content">
              <!-- <pre
                v-for="(textItem, index) in previewModalArtifact.texts"
                :key="'text-' + index"
              >{{ textItem }}
              </pre> -->
              <vue-json-pretty :data="previewModalArtifact.texts" :theme="'dark'" :show-icon="true" :show-line="true"
                :show-line-number="true" />
            </div>
          </div>

          <!-- Display Logs -->
          <div v-if="previewModalArtifact.logs.length">
            <div class="collapsible-logs">
              <div class="collapsible-header" @click="toggleLogs">
                <span>Logs</span>
                <span class="collapsible-icon">{{ showLogs ? '-' : '+' }}</span>
              </div>
              <div v-show="showLogs" class="collapsible-content">
                <!-- Logs Content -->
                <div class="logs-container">
                  <div class="log-entry" v-for="(log, index) in previewModalArtifact.logs" :key="'log-' + index">
                    <!-- Log Title using short_uuid -->
                    <h4 class="log-title">Log ID: {{ log.short_uuid }}</h4>
                    <!-- Log Content -->
                    <vue-json-pretty :data="log" :deep="2" :theme="'dark'" :show-icon="true" :show-line="true"
                      :show-line-number="true" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Display jsons -->
          <div v-if="previewModalArtifact.jsons.length">
            <div class="collapsible-logs">
              <div class="collapsible-header" @click="toggleJsons">
                <span>View Raw Logs</span>
                <span class="collapsible-icon">{{ showJsons ? '-' : '+' }}</span>
              </div>
              <div v-show="showJsons" class="collapsible-content">
                <!-- Logs Content -->
                <div class="logs-container">
                  <div class="log-entry" v-for="(json, index) in previewModalArtifact.jsons" :key="'json-' + index">
                    <!-- Log Content -->
                    <vue-json-pretty :data="json" :deep="3" :theme="'dark'" :show-icon="true" :show-line="true"
                      :show-line-number="true" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Display Links -->
          <!-- <div v-if="extractedLinksMarkdownHtml">
            <h2 class="fixed-header">
              <font-awesome-icon icon="link" class="icon" />
              Links:
            </h2>
            <div class="modal-links-content">
              <div class="markdown-content" v-html="extractedLinksMarkdownHtml"></div>
            </div>
          </div> -->


        </div>



        <!-- Modal Footer -->
        <div class="modal-buttons">
          <PrimaryButton type="standard" @click="closePreviewModal">Close</PrimaryButton>
          <div class="url-buttons">
            <!-- If more than one URL, have minibuttons to the left for the urls past the first one -->
            <MiniButton v-for="(url, index) in previewModalArtifact.urls.slice(1, 5)" :key="'mini-url-button-' + index"
              type="secondary" icon="exitarrow" @click="openUrl(url)" class="mini-url-button">
            </MiniButton>

            <PrimaryButton v-if="previewModalArtifact.urls.length" type="secondary" icon="exitarrow"
              @click="viewInPlatform">
              View in Platform
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>

    <!-- Agent Logs Modal -->
    <div v-if="showAgentLogsModal" class="modal-overlay" @click.self="closeAgentLogsModal">
      <div class="modal-content">
        <h3>Full Agentic Tasks and Reasoning</h3>
        <div class="agent-text-box" v-html="formattedAgentLogs"></div>
        <!-- <div class="agent-text-box">{{ formattedAgentLogs }}</div> -->
        <div class="modal-buttons">
          <PrimaryButton type="standard" @click="closeAgentLogsModal">Close</PrimaryButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileAvatar from './ProfileAvatar.vue';
import MiniButton from './Buttons/MiniButton.vue';
import PrimaryButton from './Buttons/PrimaryButton.vue';
import CollapsibleComponent from './CollapsibleComponent.vue';
import PreviewCard from './PreviewCard.vue';
import CodeBlock from './CodeBlock.vue';
// import CitationCounter from './CitationCounter.vue';
import EvidenceCollapsible from './EvidenceCollapsible.vue';

import { marked } from 'marked';
marked.setOptions({
  gfm: true,
  breaks: true,
  sanitize: true, // Enables sanitization of the Markdown
});

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css'; // You can choose a different theme if you prefer
import { AgGridVue } from 'ag-grid-vue3';

import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
// import { h } from 'vue';

import Highcharts from 'highcharts';
import { Chart } from 'highcharts-vue';
import { getMetricChartOptions } from '@/utils/chartUtils';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faLightbulb, faSearch, faStairs, faCaretRight, faForward } from '@fortawesome/free-solid-svg-icons';
library.add(faLightbulb, faSearch, faStairs, faCaretRight, faForward);

import { VTimeline, VTimelineItem } from 'vuetify/components';
import LogsDashboardArtifact from './LogsDashboardArtifact.vue';
import MultiMetricsDashboardArtifact from './MultiMetricsDashboardArtifact.vue';

export default {
  components: {
    ProfileAvatar,
    MiniButton,
    PrimaryButton,
    CollapsibleComponent,
    PreviewCard,
    AgGridVue,
    VueJsonPretty,
    HighchartsChart: Chart,
    // CitationCounter,
    FontAwesomeIcon,
    CodeBlock,
    EvidenceCollapsible,
    VTimeline,
    VTimelineItem,
    LogsDashboardArtifact,
    MultiMetricsDashboardArtifact,
  },
  props: {
    type: {
      type: String,
      required: true, // 'user' or 'system'
    },
    avatarSrc: {
      type: String,
      default: require('@/assets/logomark.svg'), // Default avatar image
    },
    message: {
      type: String,
      required: true, // The message content to display
    },
    parsedMessage: {
      type: Object,
      default: null,
    },
    messageId: {
      type: String,
      required: true, // Unique ID for the message
    },
    artifacts: {
      type: Array,
      default: () => [],
    },
    agentLogs: {
      type: String,
      default: '',
    },
    isStreaming: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPreviewModal: false,
      previewModalType: '',
      previewModalContent: null,
      previewModalToolName: '',
      previewModalToolArgs: {},
      showAgentLogsModal: false,
      previewModalArtifact: null,
      gridOptions: {
        rowHeight: 25,
        defaultColDef: {
          sortable: true,
          filter: true,
          resizable: true,
          wrapText: true, // Wrap Text
          autoHeight: true, // Adjust Cell Height to Fit Wrapped Text
        },
        enableCellTextSelection: true,
        animateRows: true,
      },
      quickFilterText: '',
      Highcharts,
      showLogs: true,
      showJsons: true,
      ellipsisIndex: 0,
      ellipsisOptions: ['Loading', 'Loading.', 'Loading..', 'Loading...'],
      ellipsisInterval: null,
      highlightedCard: null,
      currDataframes: [],
      currMetrics: [],
    };
  },
  computed: {
    messageTypeClass() {
      return this.type === 'system' ? 'system-message' : 'user-message';
    },
    formattedMessage() {
      if (this.parsedMessage) {
        return '';
      } else {
        const messageWithLineBreaks = this.message.replace(/\n/g, '<br>');
        return marked(messageWithLineBreaks);
      }
    },
    formattedAgentLogs() {
      // Escape HTML to prevent HTML tags from being rendered
      const escapedLogs = this.escapeHtml(this.agentLogs).replace(/\n/g, '<br>');
      return marked(escapedLogs);
    },
    //   const logsWithLineBreaks = this.agentLogs.replace(/\n/g, '<br>');
    //   return marked(logsWithLineBreaks);
    // },
    formattedPreviewToolArgs() {
      return JSON.stringify(this.previewModalToolArgs, null, 2);
    },
    agentLogsWithAnimatedEllipsis() {
      return this.agentLogs + "\n" + this.ellipsisOptions[this.ellipsisIndex];
    },
    // parsedMessage() {
    //   try {
    //     return JSON.parse(this.message);
    //   } catch (e) {
    //     // If parsing fails, treat message as a plain string
    //     return null;
    //   }
    // },
    formattedAnswer() {
      if (this.parsedMessage && this.parsedMessage.answer) {
        return marked(this.parsedMessage.answer);
      }
      return '';
    },
    getCountForToolId() {
      return (toolCallId) => {
        // Find the evidence index corresponding to the toolCallId
        if (!this.parsedMessage || !this.parsedMessage.evidence) {
          return 0;
        }
        const evidenceIndex = this.parsedMessage.evidence.findIndex(
          (evidence) => evidence.tool_id === toolCallId
        );
        return evidenceIndex !== -1 ? evidenceIndex + 1 : 0;
      };
    },
    sortedArtifacts() {
      return this.artifacts.slice().sort((a, b) => {
        const aHasCounter = this.getCountForToolId(a.tool_call_id) !== 0;
        const bHasCounter = this.getCountForToolId(b.tool_call_id) !== 0;
        return aHasCounter && !bHasCounter ? -1 : !aHasCounter && bHasCounter ? 1 : 0;
      });
    },
    extractedLinks() {
      const links = [];
      const jsons = this.previewModalArtifact.jsons;
      if (jsons && jsons.length) {
        for (const json of jsons) {
          this.findLinksInObject(json, '', links);
        }
      }
      return links;
    },
    extractedLinksMarkdownHtml() {
      if (this.extractedLinks && this.extractedLinks.length > 0) {
        let md = '';
        for (const linkObj of this.extractedLinks) {
          // md += `**Log:** \`${linkObj.path}\`\n\n**Link:** [${linkObj.url}](${linkObj.url})\n\n`;
          md += `\n**Log:**\n [\`${linkObj.path}\`](${linkObj.url})\n<br><br>\n`;

        }
        return marked(md);
      }
      return '';
    },
    // extractedLinksMarkdownHtml() {
    //   if (this.extractedLinks && this.extractedLinks.length > 0) {
    //     let md = '';
    //     for (const linkObj of this.extractedLinks) {
    //       md += `**Path:** [\`${linkObj.path}\`](${linkObj.url})\n\n`;
    //     }
    //     const renderer = new marked.Renderer();
    //     renderer.link = function(href, title, text) {
    //       return `<a href="${href}" target="_blank"${title ? ` title="${title}"` : ''}>${text}</a>`;
    //     };
    //     return marked(md, { renderer });
    //   }
    //   return '';
    // },



  },
  watch: {
    isStreaming(newVal) {
      if (newVal) {
        this.startEllipsisAnimation();
        this.$refs.collapsible.open();
      } else {
        this.stopEllipsisAnimation();
      }
    },
    showPreviewModal: 'modalVisibilityChanged',
    showAgentLogsModal: 'modalVisibilityChanged',
  },
  methods: {
    highlightCard(tool_id) {
      this.highlightedCard = tool_id;
    },
    findLinksInObject(obj, path, links) {
      if (obj && typeof obj === 'object') {
        for (const key in obj) {
          const value = obj[key];
          const newPath = path ? `${path}.${key}` : key;
          if (key === 'link' && typeof value === 'string') {
            links.push({ path: newPath, url: value });
          } else if (typeof value === 'object' && value !== null) {
            this.findLinksInObject(value, newPath, links);
          }
        }
      }
    },
    getCountForEvidenceWithToolId(index) {
      // Filter out the evidence that has tool_id and get its index
      const filteredEvidence = this.parsedMessage.evidence.filter(item => item.tool_id);
      return filteredEvidence.findIndex(item => item === this.parsedMessage.evidence[index]) + 1;
    },
    removeHighlightCard() {
      this.highlightedCard = null;
    },
    formattedEvidenceSummary(evidenceItem) {
      if (evidenceItem.summary) {
        return evidenceItem.summary;
      }
      return '';
    },
    formattedEvidenceDetails(evidenceItem) {
      if (evidenceItem.details) {
        return marked(evidenceItem.details);
      }
      return '';
    },
    artifactForToolId(tool_id) {
      return this.artifacts.find(artifact => artifact.tool_call_id === tool_id);
    },
    submitFeedback(feedbackMessage) {
      this.$emit('submit-message-feedback', this.messageId, feedbackMessage);
    },
    onKeyUp(event) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        if (this.showPreviewModal) {
          this.closePreviewModal();
        }
        if (this.showAgentLogsModal) {
          this.closeAgentLogsModal();
        }
      }
    },
    modalVisibilityChanged() {
      if (this.showPreviewModal || this.showAgentLogsModal) {
        window.addEventListener('keyup', this.onKeyUp);
      } else {
        window.removeEventListener('keyup', this.onKeyUp);
      }
    },
    toggleLogs() {
      this.showLogs = !this.showLogs;
    },
    toggleJsons() {
      this.showJsons = !this.showJsons;
    },
    openFeedbackPopup() {
      const feedbackMessage = prompt('Enter your feedback:');
      if (feedbackMessage) {
        this.$emit('submit-message-feedback', this.messageId, feedbackMessage);
      }
    },
    openPreviewModal(artifact) {
      this.previewModalArtifact = artifact;
      this.previewModalToolName = artifact.tool_name;
      this.previewModalToolArgs = artifact.tool_args;
      this.showPreviewModal = true;

      // Display Dataframes
      this.currDataframes = artifact.dataframes.map((dataframe) => ({
        currColumnDefs: this.getColumnDefs(dataframe),
        currRowData: this.getRowData(dataframe),
      }));

      // Display Metrics
      this.currMetrics = artifact.metrics.map((metricQuery) => this.getMetricChartOptions(metricQuery));

      console.log('Opening Preview Modal for Artifact: ', artifact);
    },
    openPreviewModalForToolId(toolId) {
      const artifact = this.artifacts.find((artifact) => artifact.tool_call_id === toolId);
      if (artifact) {
        this.openPreviewModal(artifact);
      }
    },
    dataframeRowCount(dataframe) {
      const firstColumn = Object.values(dataframe)[0];
      return Array.from({ length: firstColumn.length }, (_, i) => i);
    },
    closePreviewModal() {
      this.showPreviewModal = false;
      this.previewModalContent = null;
      this.previewModalToolName = '';
      this.previewModalToolArgs = {};
    },
    openAgentLogsModal() {
      this.showAgentLogsModal = true;
    },
    closeAgentLogsModal() {
      this.showAgentLogsModal = false;
    },
    escapeHtml(text) {
      const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;'
      };
      return text.replace(/[&<>"']/g, (m) => map[m]);
    },
    viewInPlatform() {
      // Use the first URL in previewModalArtifact.urls to get the platform URL
      if (this.previewModalArtifact.urls && this.previewModalArtifact.urls.length > 0) {
        const url = this.previewModalArtifact.urls[0];
        window.open(url, '_blank');
      } else {
        alert('Platform URL not available.');
      }
    },
    openUrl(url) {
      window.open(url, '_blank');
    },

    getColumnDefs(dataframe) {
      return Object.keys(dataframe).map((field) => ({
        headerName: field,
        field: field,
      }));
    },
    getRowData(dataframe) {
      const numRows = Object.values(dataframe)[0].length;
      const rows = [];
      for (let i = 0; i < numRows; i++) {
        const row = {};
        for (const key in dataframe) {
          row[key] = dataframe[key][i];
        }
        rows.push(row);
      }
      console.log("rows in modal: ", rows);
      return rows;
    },
    onGridReady(params) {
      // Get the grid API
      this.gridApi = params.api;
      this.gridApi.autoSizeAllColumns();
      this.gridApi.sizeColumnsToFit();
    },
    getMetricChartOptions(metricQuery) {
      return getMetricChartOptions(metricQuery);
    },
    startEllipsisAnimation() {
      if (this.ellipsisInterval) return;
      this.ellipsisInterval = setInterval(() => {
        this.ellipsisIndex = (this.ellipsisIndex + 1) % this.ellipsisOptions.length;
      }, 500);
    },
    stopEllipsisAnimation() {
      if (this.ellipsisInterval) {
        clearInterval(this.ellipsisInterval);
        this.ellipsisInterval = null;
        this.ellipsisIndex = 0;
      }
    },

  },
  unmounted() {
    window.removeEventListener('keyup', this.onKeyUp);
    this.stopEllipsisAnimation();
  },
};
</script>

<style scoped>
.chat-message {
  display: flex;
  width: 95%;
  padding: 0px var(--spacing-520-px, 20px);
  align-items: flex-start;
  gap: 12px;
  border-radius: 12px;
  margin-bottom: 12px;
  margin-left: auto;
  margin-right: auto;
}

.user-message {
  background: var(--colors-neutral-gray-50, #1F291F);
}

.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.text-box {
  flex: 1;
  max-width: calc(100%);
  overflow-wrap: break-word;
  margin-top: 22px;
}

.text-box .loading-spinner-container {
  margin-top: 0;
}

/* Replace your existing selector with this */
.markdown-content ::v-deep a {
  color: var(--colors-primary-green, #40853E) !important;
  text-decoration: none;
}

/* Also, update the visited, hover, and active states */
.markdown-content ::v-deep a:visited {
  color: var(--colors-primary-green, #669D65) !important;
}

.markdown-content ::v-deep a:hover {
  color: var(--colors-primary-green-dark, #669D65) !important;
}

.agent-text-box ::v-deep a {
  color: var(--colors-primary-green, #40853E) !important;
  text-decoration: none;
}

/* Also, update the visited, hover, and active states */
.agent-text-box ::v-deep a:visited {
  color: var(--colors-primary-green, #669D65) !important;
}

.agent-text-box ::v-deep a:hover {
  color: var(--colors-primary-green-dark, #669D65) !important;
}


.streaming-agent-logs {
  max-height: 200px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
  width: 100%;
}

.streaming-agent-logs::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera */
}

.inner-streaming-agent-logs {
  flex: 1;
  width: 100%;
}

.agent-logs-text {
  color: var(--colors-neutral-gray-500, #C2C5C2);
  font-family: 'Roboto Mono';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
  width: 100%;
  white-space: pre-wrap;
  word-break: break-all;
}

.faded {
  opacity: 0.4;
}





.user-message .text-box {
  color: var(--colors-neutral-gray-600, #DBDCDA);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 20px;
  align-self: stretch;
  margin-bottom: 12px;
  margin-right: 0px;
  margin-left: 0px;
}

.system-message .text-box {
  color: var(--colors-white, #FFF);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 20px;
  align-self: stretch;
  margin-bottom: 12px;
  margin-right: 0px;
  margin-left: 0px;
}

.message-content {
  width: 100%;
  max-width: 100%;
}

.message-row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 10px;
  /* Adds margin between text box and collapsible component */
  margin-bottom: 12px;
  width: 100%;
}

.system-actions {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  margin-top: 0px;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 20px;
  width: 100%;
}

.buttons-group {
  display: flex;
  gap: 4px;
}

.primary-action {
  margin-left: auto;
  margin-right: 0px;
}

.avatar {
  margin-left: 20px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.preview-cards-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-bottom: 0px;
  /* max height should be equivalent to existing heigh from textbox and other components */
  overflow-y: auto;
  /* Enable vertical scrolling */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--colors-black, #0C160B);
  padding: 20px;
  border-radius: 16px;
  width: 50%;
  max-height: 80%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--colors-neutral-gray-25-alpha, rgba(255, 255, 255, 0.04));
  box-shadow: 0px 4px 40px 0px #000;
}

.modal-header {
  /* Fixed header */
  background-color: var(--colors-green-green-600, #161F15);
  border-radius: 12px;
  display: flex;
  padding: 12px 16px;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  margin-bottom: 20px;
}

.modal-header h3,
.modal-header pre {
  color: var(--colors-green-green-600, #DBDCDA);
  font-family: 'Roboto Mono', monospace;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

.modal-content h3 {
  color: var(--colors-white, #FFF);
  margin-bottom: 10px;
  font-family: Inter, sans-serif;
  font-size: 16px;
}

.modal-header h3 {
  margin-bottom: 10px;
}

.modal-body {
  flex: 1;
  overflow-y: auto;
  padding-top: 10px;
}

.modal-error {
  color: red;
  margin-bottom: 16px;
}

.modal-error h3 {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: bold;
}

.modal-images {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  margin-bottom: 20px;
  border-radius: 8px;
}

.modal-text-content pre {
  color: var(--colors-green-green-600, #FFF);
  font-family: 'Roboto Mono', monospace;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  white-space: pre-wrap;
  /* Ensure long lines wrap */
}

.agent-text-box pre,
.modal-content pre {
  white-space: pre-wrap;
}

.agent-text-box {
  color: var(--colors-white, #FFF);
  font-family: Inter, sans-serif;
  font-size: 10px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  margin-top: 20px !important;
  background-color: var(--colors-green-green-600, #161F15);
  border-radius: 12px;
  display: flex;
  padding: 12px 16px;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  margin-bottom: 20px;
  overflow-y: auto;
  max-height: 80%;
  word-break: break-all;
}

.modal-dataframe-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.ag-theme-quartz-dark {
  width: 100%;
  max-height: 400px;
  --ag-background-color: var(--colors-neutral-gray-800, #0C160B);
  --ag-foreground-color: var(--colors-white, #FFF);
  --ag-header-background-color: var(--colors-neutral-gray-700, #2A2F28);
}

/* Adjust AG Grid fonts and colors to match your theme */
.ag-theme-quartz-dark .ag-header-cell-label .ag-header-cell-text {
  color: var(--colors-white, #FFF);
  font-family: 'Roboto Mono', monospace;
}

.ag-theme-quartz-dark .ag-cell {
  color: var(--colors-white, #FFF);
  font-family: 'Roboto Mono', monospace;
}

.ag-theme-quartz-dark.ag-custom {
  --ag-grid-size: 3px;
  --ag-list-item-height: 20px;
}


.search-bar {
  width: 90%;
  margin-bottom: 10px;
}

.quick-filter-input {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--colors-neutral-gray-600, #DBDCDA);
  border-radius: 4px;
  background-color: var(--colors-neutral-gray-800, #0C160B);
  color: var(--colors-white, #FFF);
  font-size: 14px;
}

.quick-filter-input::placeholder {
  color: var(--colors-neutral-gray-600, #DBDCDA);
}



.dataframe-table {
  width: auto;
  border-collapse: collapse;
  color: var(--colors-white, #FFF);
  font-family: 'Roboto Mono', monospace;
  margin-left: 10px;
  margin-right: 10px;
}

.dataframe-table th,
.dataframe-table td {
  border: 1px solid var(--colors-neutral-gray-600, #DBDCDA);
  padding: 4px;
  text-align: left;
}

.dataframe-table th {
  background-color: var(--colors-neutral-gray-700, #2A2F28);
}

.dataframe-table td {
  background-color: var(--colors-neutral-gray-800, #0C160B);
}

.modal-buttons {
  /* Fixed footer */
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 20px;
}

.url-buttons {
  display: flex;
  align-items: center;
  /* Ensures buttons are aligned vertically */
  gap: 4px;
  /* Space between mini buttons and primary button */
}

.mini-url-button,
.primary-button {
  vertical-align: middle;
  /* Align buttons vertically */
}

.mini-url-button {
  margin: 0;
  /* Ensure no extra margin that could affect alignment */
  padding: 0;
  /* Ensure consistent padding with the primary button */
  height: 40px;
  width: 40px;
}


.modal-buttons .primary-button {
  width: 150px;
}

.modal-logs-content {
  max-height: 400px;
  overflow-y: auto;
  background-color: #1e1e1e;
  padding: 10px;
  border-radius: 4px;
}

.modal-metrics-content {
  margin-top: 20px;
  margin-bottom: 20px;
}

.terminal-output {
  border-radius: 8px;
}

/* Collapsible Logs Styles */
.collapsible-logs {
  margin-top: 20px;
  border-radius: 8px;
}

.collapsible-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #161F15;
  padding: 12px 16px;
  cursor: pointer;
  color: var(--colors-neutral-gray-600, #DBDCDA);
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
}

.collapsible-icon {
  font-size: 18px;
}

.collapsible-content {
  padding: 10px 16px;
  background-color: #1e1e1e;
  max-height: 500px;
  overflow-y: auto;
  border-top: 1px solid var(--colors-neutral-gray-25-alpha, rgba(255, 255, 255, 0.04));
}

.collapsible-content {
  scrollbar-color: var(--colors-neutral-gray-600, #DBDCDA) #1e1e1e;
}


.logs-container {
  padding: 10px 0;
}

.log-entry {
  margin-bottom: 20px;
}

.log-title {
  font-size: 16px;
  margin-bottom: 5px;
  color: var(--colors-neutral-gray-600, #DBDCDA);
}

/* Adjust button alignment */
.modal-buttons .primary-button:first-child {
  margin-right: auto;
}

.modal-buttons .primary-button:last-child {
  margin-left: auto;
}

/* Text formatting */
.fixed-header,
.evidence-header {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
  /* Space below header */
  display: flex;
  align-items: center;
}

.fixed-header {
  color: var(--colors-primary-green, #40853E);
  text-transform: uppercase;
}

/* In your <style> section */
.fixed-header .icon,
.evidence-header .icon {
  margin-right: 8px;
  color: var(--colors-primary-green, #40853E);
  font-size: 1.2em;
  /* Adjust size as needed */
}

.header-section {
  margin-bottom: 16px;
  /* Increased spacing between Key Findings and its content */
}

.evidence-item {
  margin-bottom: 24px;
  /* Increased spacing between evidence items */
}

.inline-evidence {
  display: inline;
  /* This will make the text appear inline with the counter */
  line-height: 1.5;
  /* Adjust line height as needed */
}

/* Timeline Component Styles */
/* In your style section */
.custom-dot {
  background-color: #FFF;
  border: none;
  border-radius: 50%;
  height: 2px;
  width: 2px;
}

.evidence-timeline {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: -30px;
}





/* Style scrollbar */
.modal-body::-webkit-scrollbar {
  width: 8px;
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: var(--colors-neutral-gray-600, #DBDCDA);
  border-radius: 4px;
}

.modal-body::-webkit-scrollbar-track {
  background-color: var(--colors-neutral-gray-800, #0C160B);
}
</style>
